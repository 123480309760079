/** @jsx jsx */
import { Link } from "gatsby";
import { FunctionComponent } from "react";
import { Box, Flex, Link as ThemeLink, Text, jsx } from "theme-ui";

import { theme } from "../../../../libs/theme/src";

import { Logo } from "./logo";

export const Layout: FunctionComponent = ({ children }) => {
  return (
    <Box
      sx={{
        margin: "0 auto",
        maxWidth: "xl",
        paddingY: [6, 6, 10],
        paddingX: [6, 6, 0],
      }}
    >
      <BlogSidebar />
      <Box>{children}</Box>
    </Box>
  );
};

const BlogSidebar: FunctionComponent = () => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        alignItems: "center",
        marginRight: [0, 0, 16],
        marginBottom: 12,
      }}
    >
      <Link to="/" sx={{ height: 12, display: "block" }}>
        <Logo width={12} />
      </Link>
      <Link
        to="/"
        sx={{
          textDecoration: "none",
          color: theme.colors.text,
          fontWeight: 700,
          fontSize: "xl",
          mt: [0, 0, 2],
          ml: [4, 4, 0],
          textTransform: "lowercase",
        }}
      >
        Penance
      </Link>
      <Text sx={{ color: "secondary", fontSize: "lg" }}>self-flagellation as a service</Text>
    </Flex>
  );
};

// const BlogSidebar: FunctionComponent = () => {
//   return (
//     <Flex
//       sx={{
//         flex: "0 0 80px",
//         marginRight: [0, 0, 16],
//         marginBottom: [6, 6, 0],
//         flexDirection: ["row", "row", "column"],
//         alignItems: ["center", "center", "flex-start"],
//       }}
//     >
//       <Link to="/" sx={{ height: 12, display: "block" }}>
//         <Logo width={12} />
//       </Link>
//       <Link
//         to="/"
//         sx={{
//           textDecoration: "none",
//           color: theme.colors.text,
//           fontWeight: 700,
//           fontSize: "xl",
//           mt: [0, 0, 2],
//           ml: [4, 4, 0],
//         }}
//       >
//         penance
//       </Link>
//       <ThemeLink
//         href="https://penance.app"
//         target="_blank"
//         rel="noopener noreferrer"
//         sx={{
//           textDecoration: "none",
//           color: theme.colors.secondary,
//           fontWeight: 300,
//           fontSize: "1rem",
//           mt: [0, 0, 2],
//           ml: ["auto", "auto", 0],
//         }}
//       >
//         Sign Up
//       </ThemeLink>
//     </Flex>
//   );
// };
