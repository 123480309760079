/** @jsx jsx */
import { Link } from "gatsby";
import React, { FunctionComponent } from "react";
import { Box, Text, jsx } from "theme-ui";

import { theme } from "../../../../libs/theme/src";

export const BlogPost: FunctionComponent<{
  title: string;
  date: string;
  showPermalink?: boolean;
  link?: string;
}> = ({ title, date, link, showPermalink = true, children }) => {
  const titleTag = (
    <Text as="h1" variant="h3" sx={{ m: 0 }}>
      {title}
    </Text>
  );

  const permalink = (
    <span>
      <span sx={{ mx: 2 }}>·</span>
      <Link to={link} sx={{ color: theme.colors.secondary, textDecoration: "none" }}>
        Permalink
      </Link>
    </span>
  );

  return (
    <Box as="article" sx={{ fontWeight: 300, color: "rgb(45, 55, 72)" }}>
      {link && (
        <Link to={link} sx={{ textDecoration: "none" }}>
          {titleTag}
        </Link>
      )}
      {!link && titleTag}
      <Text
        as="p"
        sx={{
          mt: 0,
          mb: 2,
          fontWeight: 300,
          fontSize: "base",
          color: theme.colors.secondary,
        }}
      >
        {date}
        {showPermalink && permalink}
      </Text>
      {children}
    </Box>
  );
};
